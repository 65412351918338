<template>
  <v-container>
    <v-form ref="course-module-form" @submit.prevent="formSubmit">
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-btn @click.prevent="$router.go(-1)" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col md="6" cols="12">
                  <form-control
                    inputType="string"
                    v-model="title"
                    label="課堂標題"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <form-control
                    inputType="datePicker"
                    v-model="date"
                    label="課堂日期"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <form-control
                    inputType="digitalTimePicker"
                    v-model="startTime"
                    label="上堂時間"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col md="6" cols="12">
                  <form-control
                    inputType="digitalTimePicker"
                    v-model="endTime"
                    label="下堂時間"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary white--text" class="px-6" small depressed :disabled="isFetching" type="submit">
            儲存
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl'
import { mdiChevronLeft } from '@mdi/js'

export default {
  name: 'FaceCourseModule',
  components: {
    FormControl,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      title: '',
      date: '',
      startTime: '',
      endTime: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    courseID: function () {
      return this.$route.params.course_id
    },

    lessonID: function () {
      return this.$route.params.lesson_id
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getCourseDetail() {
      let courseError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.courseID),
          verify_token: this.getAdminToken(),
          get_course_only: true,
          course_mode: 'offline',
        }

        const result = await this.$XHR.api('cms_get_course_by_id', payload)
        this.$func.log('-----Get Course Detail-----')
        this.$func.log(result)

        if (this.$validate.DataValid(this.lessonID)) {
          this.getLessonDetail()
        } else {
          this.$store.dispatch('toggleLoadingPage', false)
        }
      } catch (error) {
        this.$func.log('-----Get Course Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          courseError = true
          message = '沒有權限'
        } else {
          courseError = true
        }

        this.$store.dispatch('toggleLoadingPage', false)
      } finally {
        this.setFetching(false)
      }

      if (courseError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'onlineCourse',
        })
      }
    },

    async getLessonDetail() {
      let lessonError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.lessonID),
          course_id: parseInt(this.courseID),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_lesson_by_id', payload)
        this.$func.log('-----Get Lesson Detail-----')
        this.$func.log(result)

        this.title = result.lesson_title
        this.date = result.date
        this.startTime = result.start_time
        this.endTime = result.end_time

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Lesson Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          lessonError = true
          message = '沒有權限'
        } else {
          lessonError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (lessonError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'onlineCourse',
        })
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        const validate = this.$refs['course-module-form'].validate()

        const courseHourValid = this.startTime < this.endTime

        if (!validate) {
          this.setFetching(false)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        if (!courseHourValid) {
          this.setFetching(false)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '上堂時間不可早於下堂時間',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            data_type: 'lesson_data',
            data_status: 'active',
            status: 'active',
            course_id: parseInt(this.courseID),
            lesson_title: this.title,
            date: this.date,
            start_time: this.startTime,
            end_time: this.endTime,
          }

          let key = ''
          if (!this.$validate.DataValid(this.lessonID)) {
            key = 'cms_add_lesson'
          } else {
            payload.id = parseInt(this.lessonID)
            key = 'cms_update_lesson'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----Save Lesson Data Success-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'faceCourseDetail', params: { id: this.courseID } },
          })

          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('-----Save Lesson Data Fail-----')
          this.$func.log(error)

          this.submitSuccess = false

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else {
            let msg = '儲存失敗'

            switch (error.data) {
              case 'course does not exist':
                msg = '課程不存在'
                break
              case 'lesson not found':
                msg = '課堂不存在'
                break
              case 'start time and end time required':
                msg = '請輸入上堂和下堂時間'
                break
              case 'start time cannot exceed end time':
                msg = '上堂時間不可早於下堂時間'
                break
              case 'start time required':
                msg = '請輸入上堂時間'
                break
              case 'end time required':
                msg = '請輸入下堂時間'
                break
            }

            this.setDialogMessage({
              message: msg,
              isError: false,
              returnLink: null,
            })
            this.setShowDialog(true)
          }

          this.$store.dispatch('toggleLoadingPage', false)
        } finally {
          this.setFetching(false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.setFetching(true)
      this.getCourseDetail()
    }
  },
}
</script>